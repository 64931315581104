import React, { useEffect, useState } from "react";
import { defaultMenu, QuickLinksMenu } from "./LeftNavMenu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, Stack, tooltipClasses, TooltipProps } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StorageInfo from "../../../models/StorageInfo";
import { bytesToSize, fetchData } from "../../../utils/utils";
import { API_URL } from "../../../utils/config";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import StorageCard from "../../../components/StorageCard";
import { useIAMPermission } from "../../../store/userStore";
import NavigationItem from "../../CoreLayout/NavigationItem";
import { useTranslation } from "react-i18next";
import SettingsTooltipItem from "../../CoreLayout/SettingsTooltipItem";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";

type LeftNavBarProps = {
  open: boolean;
};
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function LeftNavBar({ open }: LeftNavBarProps) {
  const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(false);
  const location = useLocation();
  const [storage, setStorage] = useState<StorageInfo | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const [flg, setFlg] = React.useState(false);
  const { t } = useTranslation();

  /* GET Storage Info */
  useEffect(() => {
    fetchData(`${API_URL}/storage/info`)
      .then((data) => {
        setStorage(data);
      })
      .catch((ex) => {
        enqueueSnackbar(ex.message, { variant: "error" });
      });
  }, [enqueueSnackbar]);
  const routeChange = (event: React.MouseEvent<HTMLDivElement>) => {
    let path = event.currentTarget.id;
    if (!isQuickLinkItem(path) && isQuickLinksOpen) {
      setIsQuickLinksOpen(!isQuickLinksOpen);
    }
  };

  const onQuickLinkClick = () => {
    setIsQuickLinksOpen(!isQuickLinksOpen);
  };

  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };
  const isQuickLinkItem = (path: string) => {
    return path.includes("control-panel");
  };

  const iam = useIAMPermission();

  const accessMenu = QuickLinksMenu.filter((item) => {
    if (item.permissions) {
      return iam.hasSome(item.permissions);
    }
    return true;
  });

  const quickLinksName = t("sidenav.quickLinks");

  const quickLinksTooltip = () => {
    return (
      <Stack flexDirection={"column"}>
        {accessMenu.map((tag, index) => (
          <SettingsTooltipItem
            key={index}
            name={t(tag.name)}
            isActive={isActive}
            icon={tag.icon}
            setFlg={setFlg}
            onClick={routeChange}
            path={tag.path}
          />
        ))}
      </Stack>
    );
  };

  return (
    <List>
      {defaultMenu.map((tag, index) => (
        <NavigationItem
          key={index}
          open={open}
          path={tag.path}
          onClick={routeChange}
          icon={tag.icon}
          name={t(tag.name)}
          isActive={isActive}
        />
      ))}
      {!open ? (
        <ClickAwayListener onClickAway={() => setFlg(false)}>
          <div>
            <LightTooltip
              title={quickLinksTooltip()}
              placement="bottom-start"
              open={flg}
            >
              <div>
                <Tooltip title={quickLinksName} placement="right" arrow>
                  <ListItemButton
                    id={"settings"}
                    onClick={() => setFlg(!flg)}
                    sx={{
                      ml: 1,
                      width: 55,
                      height: 40,
                    }}
                  >
                    <LinkOutlinedIcon
                      sx={{ color: "grey.650" }}
                      fontSize={"small"}
                    />
                  </ListItemButton>
                </Tooltip>
              </div>
            </LightTooltip>
          </div>
        </ClickAwayListener>
      ) : (
        <ListItemButton
          id={"settings"}
          onClick={() => onQuickLinkClick()}
          sx={{
            ml: 1,
            width: 232,
            height: 40,
          }}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon
              fontSize={"small"}
              sx={{ color: "grey.650" }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography color={"grey.650"} variant={"body2"}>
              {quickLinksName}
            </Typography>
          </ListItemText>
          {isQuickLinksOpen ? (
            <ExpandLess fontSize={"small"} />
          ) : (
            <ExpandMore fontSize={"small"} />
          )}
        </ListItemButton>
      )}

      {open && (
        <Collapse in={isQuickLinksOpen}>
          <List component="div" disablePadding>
            {accessMenu.map((tag, index) => (
              <Link
                key={index}
                to={`/${tag.path}`}
                style={{ textDecoration: "none", color: "#000" }}
              >
                <ListItemButton
                  key={index}
                  id={tag.path}
                  onClick={(event) => routeChange(event)}
                  sx={{
                    ml: 3,
                    width: 215,
                    height: 40,
                  }}
                >
                  <ListItemIcon>
                    <tag.icon
                      sx={{
                        color: isActive(tag.path) ? "primary.main" : "grey.650",
                        fontSize: 18,
                      }}
                      fontSize={"small"}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      color={isActive(tag.path) ? "primary.main" : "grey.650"}
                      variant={"body2"}
                    >
                      {t(tag.name)}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </Link>
            ))}
          </List>
        </Collapse>
      )}
      <Divider sx={{ my: 2 }} />
      {open ? (
        <Link
          to={`/store/storage`}
          style={{ textDecoration: "none", color: "#000" }}
        >
          <StorageCard info={storage} />
        </Link>
      ) : (
        <Link
          to={`/store/storage`}
          style={{ textDecoration: "none", color: "#000" }}
        >
          <Tooltip
            title={`${bytesToSize(
              storage?.totalUsableSpace ?? 0
            )} / ${bytesToSize(storage?.totalMaxSpace ?? 0)}`}
            placement="right"
            arrow
          >
            <ListItemButton
              id={"storage"}
              sx={{
                ml: 1,
                width: 55,
                height: 40,
              }}
            >
              <CloudOutlinedIcon
                sx={{
                  color: isActive("storage") ? "primary.main" : "grey.650",
                }}
                fontSize={"small"}
              />
            </ListItemButton>
          </Tooltip>
        </Link>
      )}
    </List>
  );
}
export default LeftNavBar;
