import { SvgIconTypeMap } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useMemo } from "react";
import {
  filetypeOptions,
  ownerOptions,
  publishStatusOptions,
  typeOptions,
} from "../../pages/Search/Filters/FilterOption";
import { useLocationOptions } from "../../hooks/search/useLocationOptions";
import useQuery from "../../hooks/query/useQuery";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  buildParameters,
  decodeFromBase64,
  encodeToBase64,
} from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContainerTypeList } from "../../store/containerTypeStore";
import { useDatastreamTypeList } from "../../store/datastreamTypeStore";
import { useArrayOptions } from "../../hooks/common/useArrayOptions";
import DatastreamType from "../../models/DatastreamType";
import ContainerType from "../../models/ContainerType";
import { useMap } from "../../hooks/common/useMap";
import { Option } from "../../hooks/form/useFormElements";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PropertiesConfig from "./PropertiesConfig";
import { useFilters } from "../../hooks/common/useFilters";

type OptionType = {
  icon: OverridableComponent<SvgIconTypeMap>;
  title: string;
  keyValue: string;
  query: string;
  color?: string;
};
type SettingsPoperProps = {
  open: boolean;
  handleClose: () => void;
};

const initialValues = {
  type: null,
  filetype: [],
  datastreamTypes: [],
  containerTypes: [],
  location: null,
  owner: null,
  publishStatus: null,
  text: "",
  properties: [],
  caseSensitive: false,
  fullText: false,
  isTrashed: false,
};

const SettingsPopper = ({ handleClose }: SettingsPoperProps) => {
  const searchParams = useQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [containerTypes] = useContainerTypeList();
  const [datastreamTypes] = useDatastreamTypeList();
  const datastreamTypesOptions = useArrayOptions<DatastreamType>(
    datastreamTypes,
    "uuid",
    "name"
  );
  const containerTypesOptions = useArrayOptions<ContainerType>(
    containerTypes,
    "uuid",
    "name"
  );
  const ctMap = useMap(containerTypesOptions, "id");
  const dtMap = useMap(datastreamTypesOptions, "id");
  const filetypeMap = useMap(filetypeOptions, "query");

  /* READ SEARCH PARAMS */
  const typeQuery = searchParams.get("type");
  const typeOption = typeOptions.find((x) => x.query === typeQuery) ?? null;

  const filetypeQuery = searchParams.get("filetype");
  const filetypeOption = filetypeQuery ? filetypeQuery.split(",") : [];

  const filetypeValue = filetypeOption.map((id) => {
    const option = filetypeMap.get(id);
    return (
      option ?? {
        icon: ImageOutlinedIcon,
        title: "search.type.image",
        id: "Image",
        keyValue: "filetype",
        query: "IMAGE",
        color: "#FF6C40",
      }
    );
  });

  const datastreamTypesQuery = searchParams.get("datastreamTypes");
  const datastreamTypesOption = datastreamTypesQuery
    ? datastreamTypesQuery.split(",")
    : [];
  const dtValue = datastreamTypesOption.map((id) => {
    const option = dtMap.get(id);
    return option ?? { id, name: id };
  });

  const containerTypesQuery = searchParams.get("containerTypes");
  const containerTypesOption = containerTypesQuery
    ? containerTypesQuery.split(",")
    : [];
  const ctValue = containerTypesOption.map((id) => {
    const option = ctMap.get(id);
    return option ?? { id, name: id };
  });

  const locationQuery = searchParams.get("location");
  console.log(locationQuery);
  const locationOptions = useLocationOptions(locationQuery);
  const locationOption = locationQuery
    ? locationOptions.find((x) => x.query === locationQuery) ?? null
    : null;

  const ownerQuery = searchParams.get("owner");
  const ownerOption = ownerOptions.find((x) => x.query === ownerQuery) ?? null;

  const textQuery = searchParams.get("text") ?? "";

  const fullText = Boolean(searchParams.get("fullText"));

  const isTrashed = Boolean(searchParams.get("isTrashed"));

  const caseSensitiveQuery = Boolean(searchParams.get("caseSensitive"));

  const publishStatusQuery = searchParams.get("publishStatus");
  const publishStatusOption =
    publishStatusOptions.find((x) => x.query === publishStatusQuery) ?? null;

  const propertiesQuery = searchParams.get("properties");
  const decodedPropertiesQuery = propertiesQuery
    ? decodeFromBase64(propertiesQuery)
    : null;
  const propertyFilters = useMemo(() => {
    return decodedPropertiesQuery ? JSON.parse(decodedPropertiesQuery) : [];
  }, [decodedPropertiesQuery]);

  const [form, handleChange, setForm, handleChangeArray] = useFilters({
    type: typeOption,
    filetype: filetypeValue,
    datastreamTypes: dtValue,
    containerTypes: ctValue,
    location: locationOption,
    owner: ownerOption,
    publishStatus: publishStatusOption,
    text: textQuery,
    properties: propertyFilters,
    caseSensitive: caseSensitiveQuery,
    fullText: fullText,
    isTrashed: isTrashed,
  });

  const containerTypes_search_payload = useMemo(() => {
    if (form.containerTypes && form.containerTypes.length) {
      return form.containerTypes.map((item) => item.id).toString();
    }
    return "";
  }, [form.containerTypes]);

  const datastreamTypes_search_payload = useMemo(() => {
    if (form.datastreamTypes && form.datastreamTypes.length) {
      return form.datastreamTypes.map((item) => item.id).toString();
    }
    return "";
  }, [form.datastreamTypes]);

  const filetype_search_payload = useMemo(() => {
    if (form.filetype && form.filetype.length) {
      return form.filetype.map((item) => item.query).toString();
    }
    return "";
  }, [form.filetype]);

  const properties_search_payload = useMemo(() => {
    if (
      (form.containerTypes && form.containerTypes.length === 1) ||
      (form.datastreamTypes && form.datastreamTypes.length === 1)
    ) {
      if (!form.properties.length) return "";
      const strQuery = JSON.stringify(form.properties);
      return encodeToBase64(strQuery);
    }
    return "";
  }, [form.properties, form.containerTypes, form.datastreamTypes]);
  console.log(form.properties);
  const handleSearch = () => {
    const parameters = buildParameters({
      text: form.text,
      caseSensitive: form.caseSensitive ? form.caseSensitive : null,
      location: form.location && form.location.query,
      type: form.type && form.type.query,
      owner: form.owner && form.owner.query,
      publishStatus: form.publishStatus && form.publishStatus.query,
      filetype: filetype_search_payload ? filetype_search_payload : null,
      containerTypes: containerTypes_search_payload
        ? containerTypes_search_payload
        : null,
      datastreamTypes: datastreamTypes_search_payload
        ? datastreamTypes_search_payload
        : null,
      properties: properties_search_payload ? properties_search_payload : null,
      isTrashed: form.isTrashed ? form.isTrashed : null,
      fullText: form.fullText ? form.fullText : null,
    });
    navigate(`/store/search${parameters}`);
    handleClose();
  };

  const handleClear = () => {
    setForm(initialValues);
  };

  const disableClear = useMemo(() => {
    return (
      !form.location &&
      !form.type &&
      !form.owner &&
      !form.text &&
      !form.publishStatus &&
      !form.fullText
    );
  }, [
    form.location,
    form.type,
    form.owner,
    form.text,
    form.publishStatus,
    form.fullText,
  ]);

  return (
    <Paper
      sx={{
        width: "600px",
        mr: -1,
        mt: 0.4,
      }}
      elevation={6}
    >
      <Stack flexDirection={"column"} rowGap={2} p={2}>
        {form.location && (
          <Autocomplete
            value={form.location}
            onChange={(event: any, newValue: OptionType | null) => {
              handleChange("location")(newValue);
            }}
            disablePortal
            disabled={form.fullText}
            options={locationOptions}
            getOptionLabel={(option: OptionType) => t(option.title)}
            size={"small"}
            includeInputInList
            renderOption={(props, option) => (
              <>
                {option.title && (
                  <Box component="li" sx={{ flexShrink: 0 }} {...props}>
                    <option.icon sx={{ mr: 2 }} fontSize={"medium"} />
                    <Typography variant={"body1"}>{t(option.title)}</Typography>
                  </Box>
                )}
              </>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("search.location")}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            sx={{ width: 220 }}
          />
        )}
        <Autocomplete
          value={form.type}
          onChange={(event: any, newValue: OptionType | null) => {
            handleChange("type")(newValue);
          }}
          disablePortal
          options={typeOptions}
          getOptionLabel={(option: OptionType) => t(option.title)}
          size={"small"}
          disabled={form.fullText}
          includeInputInList
          renderOption={(props, option) => (
            <Box component="li" sx={{ flexShrink: 0 }} {...props}>
              <option.icon
                sx={{ mr: 2, color: option.color }}
                fontSize={"medium"}
              />
              <Typography variant={"body1"}>{t(option.title)}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("search.type")}
              inputProps={{
                ...params.inputProps,
                autoComplete: "off", // disable autocomplete and autofill
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          sx={{ width: 220 }}
        />
        {form.type && form.type.query === "DATASTREAM" && (
          <Autocomplete
            value={form.datastreamTypes}
            onChange={(event: any, newValue) => {
              handleChange("datastreamTypes")(newValue);
            }}
            size={"small"}
            multiple
            disablePortal
            options={datastreamTypesOptions}
            getOptionLabel={(option: Option) => option.name}
            includeInputInList
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("search.datastreamTypes")}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: "340px",
              },
            }}
            sx={{ width: 220 }}
          />
        )}
        {form.type && form.type.query === "CONTAINER" && (
          <Autocomplete
            value={form.containerTypes}
            onChange={(event: any, newValue) => {
              handleChange("containerTypes")(newValue);
            }}
            size={"small"}
            disablePortal
            multiple
            options={containerTypesOptions}
            disabled={form.fullText}
            getOptionLabel={(option: Option) => option.name}
            includeInputInList
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("search.containerTypes")}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: "340px",
              },
            }}
            sx={{ width: 220 }}
          />
        )}
        {((form.containerTypes && form.containerTypes.length === 1) ||
          (form.datastreamTypes && form.datastreamTypes.length === 1)) && (
          <PropertiesConfig
            types={
              form.type && form.type.query === "CONTAINER"
                ? form.containerTypes
                : form.datastreamTypes
            }
            isContainerType={!!(form.type && form.type.query === "CONTAINER")}
            propertyFilters={form.properties}
            handleChange={handleChangeArray}
          />
        )}
        {form.type && form.type.query === "DATASTREAM" && (
          <Autocomplete
            value={form.filetype}
            onChange={(event: any, newValue) => {
              handleChange("filetype")(newValue);
            }}
            size={"small"}
            disablePortal
            multiple
            options={filetypeOptions}
            getOptionLabel={(option: OptionType) => t(option.title)}
            includeInputInList
            renderOption={(props, option) => (
              <Box component="li" sx={{ flexShrink: 0 }} {...props}>
                <option.icon
                  sx={{ mr: 2, color: option.color }}
                  fontSize={"medium"}
                />
                <Typography variant={"body1"}>{t(option.title)}</Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("search.filetype")}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: "340px",
              },
            }}
            sx={{ width: 220 }}
          />
        )}
        <Autocomplete
          value={form.publishStatus}
          onChange={(event: any, newValue: OptionType | null) => {
            handleChange("publishStatus")(newValue);
          }}
          size={"small"}
          disablePortal
          options={publishStatusOptions}
          disabled={form.fullText}
          getOptionLabel={(option: OptionType) => t(option.title)}
          includeInputInList
          renderOption={(props, option) => (
            <Box component="li" sx={{ flexShrink: 0 }} {...props}>
              <option.icon
                sx={{ mr: 2, color: option.color }}
                fontSize={"medium"}
              />
              <Typography variant={"body1"}>{t(option.title)}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("search.publishStatus")}
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          sx={{ width: 220 }}
        />
        <Autocomplete
          value={form.owner}
          onChange={(event: any, newValue: OptionType | null) => {
            handleChange("owner")(newValue);
          }}
          disablePortal
          options={ownerOptions}
          getOptionLabel={(option: OptionType) => t(option.title)}
          size={"small"}
          includeInputInList
          renderOption={(props, option) => (
            <Box component="li" sx={{ flexShrink: 0 }} {...props}>
              <option.icon sx={{ mr: 2 }} fontSize={"medium"} />
              <Typography variant={"body1"}>{t(option.title)}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("search.owner")}
              inputProps={{
                ...params.inputProps,
                autoComplete: "off", // disable autocomplete and autofill
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          sx={{ width: 220 }}
        />
        <TextField
          size={"small"}
          label={t("search.text")}
          value={form.text}
          onChange={handleChange("text")}
          inputProps={{
            autoComplete: "new-password",
            maxLength: 128,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant={"body2"}>
                  {form.text.length
                    ? `${128 - form.text.length} chars left`
                    : ""}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.caseSensitive}
                onChange={handleChange("caseSensitive")}
                color="primary"
                size={"small"}
              />
            }
            label={t("search.caseSensitive")}
            labelPlacement="end"
            sx={{ m: 0 }}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.isTrashed}
                onChange={handleChange("isTrashed")}
                color="primary"
                size={"small"}
              />
            }
            label={t("search.inTrash")}
            disabled={form.fullText}
            labelPlacement="end"
            sx={{ m: 0, mt: -1, p: 0 }}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.fullText}
                onChange={(event) => {
                  setForm({
                    ...form,
                    type:
                      typeOptions.find((x) => x.query === "DATASTREAM") ?? null,
                    publishStatus:
                      publishStatusOptions.find((x) => x.query === "ALL") ??
                      null,
                    fullText: event.target.checked,
                    isTrashed: form.isTrashed
                      ? !form.isTrashed
                      : form.isTrashed,
                  });
                }}
                color="primary"
                size={"small"}
              />
            }
            label={t("search.fullText")}
            labelPlacement="end"
            sx={{ m: 0, mt: -1, p: 0 }}
          />
        </FormControl>
        <Divider />
        <Stack flexDirection={"row"} columnGap={1} justifyContent={"flex-end"}>
          <Button
            variant="outlined"
            onClick={handleClear}
            disabled={disableClear}
          >
            {t("toolbar.searchbar.clear")}
          </Button>
          <Button variant="contained" onClick={handleSearch} size={"small"}>
            {t("toolbar.searchbar.search")}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default SettingsPopper;
