import { Navigate, useRoutes } from "react-router-dom";
import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import GuideLayout from "./extensions/guide/layouts/GuideLayout";
import XRLayout from "./layouts/XRLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword";
import SharedView from "./pages/SharedView";
import ResetPassword from "./pages/ResetPassword";
import PortalLayout from "./layouts/PortalLayout";
import Surveys from "./extensions/surveys/pages";
import SurveyDetails from "./extensions/surveys/pages/surveyDetails";
import SurveyLayout from "./extensions/surveys/layouts/SurveyLayout";
import GeoLayout from "./layouts/GeoLayout";

// XR
const XRApplications = React.lazy(
  () => import("./extensions/xr/pages/applications")
);
const ApplicationDetails = React.lazy(
  () =>
    import(
      "./extensions/xr/pages/applications/ApplicationDetails/ApplicationDetails"
    )
);

//GUIDE
const GuideApplications = React.lazy(
  () => import("./extensions/guide/pages/Applications")
);
const GuideApplicationDetails = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails")
);
const GuideItems = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Items")
);
const GuideDashboard = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Dashboard")
);
const GuideNavigation = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Navigation")
);
const GuideNavigationLayout = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Navigation/Layout")
);
const GuidePages = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Pages")
);
const GuidePageEdit = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Pages/PagesEdit")
);
const GuideItemEdit = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Items/ItemsEdit")
);
const GuideReleases = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Releases")
);
const GuideBuilds = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Builds")
);
const GuideLists = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Lists")
);
const GuideListEdit = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Lists/ListsEdit")
);
const GuideRoutes = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Routes")
);
const GuideRoutesEdit = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Routes/RoutesEdit")
);
const GuideSettings = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Settings")
);
const GuideOnboarding = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Onboarding")
);

const GuideTheme = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Theme")
);
const GuideThemeSettings = React.lazy(
  () =>
    import("./extensions/guide/pages/ApplicationDetails/Theme/ThemeSettings")
);
const Beacons = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/Beacons")
);
const BeaconDetails = React.lazy(
  () =>
    import("./extensions/guide/pages/ApplicationDetails/Beacons/BeaconDetails")
);
const GuideStoreMetadata = React.lazy(
  () => import("./extensions/guide/pages/ApplicationDetails/StoreMetadata")
);

//PORTAL
const Portals = React.lazy(() => import("./extensions/portal/pages/Portals"));
const PortalDetails = React.lazy(
  () => import("./extensions/portal/pages/PortalDetails")
);
const PortalNavigation = React.lazy(
  () => import("./extensions/portal/pages/PortalDetails/Navigation")
);
const PortalMenuDetails = React.lazy(
  () => import("./extensions/portal/pages/PortalDetails/Navigation/MenuDetails")
);
const PortalPages = React.lazy(
  () => import("./extensions/portal/pages/PortalDetails/Pages")
);
const PortalPageEdit = React.lazy(
  () => import("./extensions/portal/pages/PortalDetails/Pages/PagesEdit")
);
const PortalAppDetails = React.lazy(
  () => import("./extensions/portal/pages/PortalDetails/AppDetails")
);

// GEO
const GeoMap = React.lazy(() => import("./extensions/geo/pages/Map"));

//STORE
const ChatGPT = React.lazy(
  () => import("./extensions/chat-gpt/pages/Dashboard")
);
const Search = React.lazy(() => import("./pages/Search"));
const ManageAccount = React.lazy(() => import("./pages/ManageAccount"));
const Feedback = React.lazy(
  () => import("./pages/ControlPanel/AdministrativeTools/Feedback")
);
const OAIPMH = React.lazy(
  () => import("./pages/ControlPanel/Interoperability/OAI-PMH")
);
const Workflows = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/Workflows")
);
const WorkflowDetails = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/Workflows/WorkflowDetails")
);
const OAIPMHSetDetails = React.lazy(
  () => import("./pages/ControlPanel/Interoperability/OAI-PMH/SetDetails")
);
const Content = React.lazy(() => import("./pages/Content"));
const ContainerDetails = React.lazy(
  () => import("./pages/Content/Containers/ContainerDetails")
);
const Recent = React.lazy(() => import("./pages/Recent"));
const Events = React.lazy(
  () => import("./pages/ControlPanel/AdministrativeTools/Events")
);
const Popular = React.lazy(() => import("./pages/Popular"));
const Reports = React.lazy(() => import("./pages/Reports/Reports"));
const Trash = React.lazy(() => import("./pages/Trash"));
const Storage = React.lazy(() => import("./pages/Storage"));
const SystemSettings = React.lazy(
  () => import("./pages/ControlPanel/AdministrativeTools/SystemSettings")
);
const ContentModel = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/ContentModel")
);
const MetadataSchemas = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/MetadataSchemas")
);
const Attributes = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/MetadataSchemas/Attributes")
);
const AttributeDetails = React.lazy(
  () =>
    import(
      "./pages/ControlPanel/DataModeling/MetadataSchemas/Attributes/AttributeDetails"
    )
);
const PropertyGroups = React.lazy(
  () =>
    import("./pages/ControlPanel/DataModeling/MetadataSchemas/PropertyGroups")
);
const PropertyDetails = React.lazy(
  () =>
    import(
      "./pages/ControlPanel/DataModeling/MetadataSchemas/Properties/PropertyDetails"
    )
);
const Mappings = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/MetadataSchemas/Mappings")
);
const MappingDetails = React.lazy(
  () =>
    import(
      "./pages/ControlPanel/DataModeling/MetadataSchemas/Mappings/MappingDetails"
    )
);
const Namespaces = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/MetadataSchemas/Namespaces")
);
const NamespaceDetails = React.lazy(
  () =>
    import(
      "./pages/ControlPanel/DataModeling/MetadataSchemas/Namespaces/NamespaceDetails"
    )
);
const Vocabularies = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/MetadataSchemas/Vocabularies")
);
const VocabularyDetails = React.lazy(
  () =>
    import(
      "./pages/ControlPanel/DataModeling/MetadataSchemas/Vocabularies/VocabularyDetails/VocabularyDetails"
    )
);
const Schemas = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/MetadataSchemas/Schemas")
);
const SchemaDetails = React.lazy(
  () =>
    import(
      "./pages/ControlPanel/DataModeling/MetadataSchemas/Schemas/SchemaDetails"
    )
);
const ContainerTypes = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/ContentModel/ContainerTypes")
);
const ContainerTypeDetails = React.lazy(
  () =>
    import(
      "./pages/ControlPanel/DataModeling/ContentModel/ContainerTypes/ContainerTypeDetails"
    )
);
const DatastreamTypes = React.lazy(
  () => import("./pages/ControlPanel/DataModeling/ContentModel/DatastreamTypes")
);
const DatastreamTypeDetails = React.lazy(
  () =>
    import(
      "./pages/ControlPanel/DataModeling/ContentModel/DatastreamTypes/DatastreamTypeDetails/DatastreamTypeDetails"
    )
);
const UserManagement = React.lazy(
  () => import("./pages/ControlPanel/UserManagement")
);
const Groups = React.lazy(
  () => import("./pages/ControlPanel/UserManagement/Groups")
);
const GroupDetails = React.lazy(
  () => import("./pages/ControlPanel/UserManagement/Groups/GroupDetails")
);
const Users = React.lazy(
  () => import("./pages/ControlPanel/UserManagement/Users")
);
const UserDetails = React.lazy(
  () => import("./pages/ControlPanel/UserManagement/Users/UserDetails")
);
const Tags = React.lazy(() => import("./pages/ControlPanel/DataModeling/Tags"));
const AdminConsole = React.lazy(() => import("./pages/ControlPanel"));
const AdministrativeTools = React.lazy(
  () => import("./pages/ControlPanel/AdministrativeTools")
);
const DataModeling = React.lazy(
  () => import("./pages/ControlPanel/DataModeling")
);
const Integration = React.lazy(
  () => import("./pages/ControlPanel/Integration")
);
const Interoperability = React.lazy(
  () => import("./pages/ControlPanel/Interoperability")
);
const SystemInfo = React.lazy(
  () => import("./pages/ControlPanel/AdministrativeTools/SystemInfo")
);
const Indexing = React.lazy(
  () => import("./pages/ControlPanel/AdministrativeTools/SystemInfo/Indexing")
);
const ApiKeys = React.lazy(
  () => import("./pages/ControlPanel/Integration/ApiKeys")
);
const ApiKeyDetails = React.lazy(
  () => import("./pages/ControlPanel/Integration/ApiKeys/ApiKeyDetails")
);
const ReleaseNotes = React.lazy(() => import("./pages/ReleaseNotes"));
const Jobs = React.lazy(
  () => import("./pages/ControlPanel/AdministrativeTools/Jobs")
);
const JobDetails = React.lazy(
  () => import("./pages/ControlPanel/AdministrativeTools/Jobs/JobDetails")
);
const Notifications = React.lazy(() => import("./pages/Notifications"));
const NotificationDetails = React.lazy(
  () => import("./pages/Notifications/NotificationDetails")
);
const ContainerActivity = React.lazy(
  () => import("./pages/Content/Containers/ContainerActivity")
);
const DatastreamActivity = React.lazy(
  () => import("./pages/Content/Datastreams/DatastreamActivity")
);

const LoadingComponent = () => {
  return (
    <Stack
      position={"absolute"}
      top={"0"}
      left={"0"}
      height={"100vh"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CircularProgress />
    </Stack>
  );
};

export default function Routes() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/store/content" />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/urls/:id",
      element: <SharedView />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/store",
      element: <DefaultLayout />,
      children: [
        {
          path: "content",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Content />
            </React.Suspense>
          ),
        },
        {
          path: "content/:id",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ContainerDetails />
            </React.Suspense>
          ),
        },
        {
          path: "container/:uuid/activity",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ContainerActivity />
            </React.Suspense>
          ),
        },
        {
          path: "datastream/:uuid/activity",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <DatastreamActivity />
            </React.Suspense>
          ),
        },
        {
          path: "recent",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Recent />
            </React.Suspense>
          ),
        },
        {
          path: "popular",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Popular />
            </React.Suspense>
          ),
        },
        {
          path: "reports",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Reports />
            </React.Suspense>
          ),
        },
        {
          path: "trash",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Trash />
            </React.Suspense>
          ),
        },
        {
          path: "storage",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Storage />
            </React.Suspense>
          ),
        },
        {
          path: "chat-gpt",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ChatGPT />
            </React.Suspense>
          ),
        },
        {
          path: "search",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Search />
            </React.Suspense>
          ),
        },
        {
          path: "manage-account",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ManageAccount />
            </React.Suspense>
          ),
        },
        {
          path: "release-notes",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ReleaseNotes />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <AdminConsole />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/administrative-tools",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <AdministrativeTools />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/administrative-tools/event-log",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Events />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/administrative-tools/feedback",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Feedback />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/administrative-tools/system-info",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <SystemInfo />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/administrative-tools/system-info/indexing",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Indexing />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/administrative-tools/jobs",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Jobs />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/administrative-tools/jobs/:id",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <JobDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/administrative-tools/system-settings",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <SystemSettings />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <DataModeling />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/content-model",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ContentModel />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/content-model/container-types",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ContainerTypes />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/content-model/container-types/:id",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ContainerTypeDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/content-model/datastream-types",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <DatastreamTypes />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/content-model/datastream-types/:id",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <DatastreamTypeDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <MetadataSchemas />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/attributes",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Attributes />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/attributes/:uuid",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <AttributeDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/properties",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <PropertyGroups />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/properties/:uuid",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <PropertyDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/namespaces",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Namespaces />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/namespaces/:prefix",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <NamespaceDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/mappings",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Mappings />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/mappings/:uuid",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <MappingDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/vocabularies",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Vocabularies />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/vocabularies/:id",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <VocabularyDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/schemas",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Schemas />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/metadata-schemas/schemas/:uuid",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <SchemaDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/workflows",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Workflows />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/workflows/:id",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <WorkflowDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/data-modeling/tags",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Tags />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/integration",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Integration />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/integration/api-keys",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ApiKeys />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/integration/api-keys/:id",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <ApiKeyDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/interoperability",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Interoperability />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/interoperability/oai-pmh",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <OAIPMH />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/interoperability/oai-pmh/sets/:id",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <OAIPMHSetDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/user-management",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <UserManagement />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/user-management/groups",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Groups />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/user-management/groups/:id",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <GroupDetails />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/user-management/users",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Users />
            </React.Suspense>
          ),
        },
        {
          path: "control-panel/user-management/users/:uuid",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <UserDetails />
            </React.Suspense>
          ),
        },
        {
          path: "notifications",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <Notifications />
            </React.Suspense>
          ),
        },
        {
          path: "notifications/:uuid",
          element: (
            <React.Suspense fallback={<LoadingComponent />}>
              <NotificationDetails />
            </React.Suspense>
          ),
        },
      ],
    },
    //XR
    {
      path: "/xr",
      element: <Navigate to="/xr/applications" />,
    },
    {
      path: "/xr/applications",
      element: (
        <XRLayout
          children={
            <React.Suspense fallback={<LoadingComponent />}>
              <XRApplications />
            </React.Suspense>
          }
        />
      ),
    },
    {
      path: "/xr/applications/:uuid",
      element: (
        <XRLayout
          children={
            <React.Suspense fallback={<LoadingComponent />}>
              <ApplicationDetails />
            </React.Suspense>
          }
        />
      ),
    },
    // SURVEYS
    {
      path: "/surveys",
      element: (
        <SurveyLayout
          children={
            <React.Suspense fallback={<LoadingComponent />}>
              <Surveys />
            </React.Suspense>
          }
        />
      ),
    },
    {
      path: "/surveys/:surveyUUID",
      element: (
        <SurveyLayout
          children={
            <React.Suspense fallback={<LoadingComponent />}>
              <SurveyDetails />
            </React.Suspense>
          }
        />
      ),
    },

    //GUIDE
    {
      path: "/guide",
      element: <Navigate to="/guide/applications" />,
    },
    {
      path: "/guide/applications",
      element: (
        <GuideLayout
          children={
            <React.Suspense fallback={<LoadingComponent />}>
              <GuideApplications />
            </React.Suspense>
          }
        />
      ),
    },
    {
      path: "/guide/applications/:guideUUID",
      children: [
        {
          path: "settings",
          element: <GuideSettings />,
        },
        {
          path: "onboarding",
          element: <GuideOnboarding />,
        },
        {
          path: "pages",
          element: <GuidePages />,
        },
        {
          path: "pages/:pageUUID",
          element: <GuidePageEdit />,
        },
        {
          path: "pages/new",
          element: <GuidePageEdit />,
        },
        {
          path: "items",
          element: <GuideItems />,
        },
        {
          path: "items/:itemUUID",
          element: <GuideItemEdit />,
        },
        {
          path: "items/new",
          element: <GuideItemEdit />,
        },
        {
          path: "metadata",
          element: <GuideStoreMetadata />,
        },
        {
          path: "releases",
          element: <GuideReleases />,
        },
        {
          path: "builds",
          element: <GuideBuilds />,
        },
        {
          path: "dashboard",
          element: <GuideDashboard />,
        },
        {
          path: "navigation",
          element: <GuideNavigation />,
        },
        {
          path: "layout",
          element: <GuideNavigationLayout />,
        },
        {
          path: "lists",
          element: <GuideLists />,
        },
        {
          path: "lists/:listUUID",
          element: <GuideListEdit />,
        },
        {
          path: "lists/new",
          element: <GuideListEdit />,
        },
        {
          path: "routes",
          element: <GuideRoutes />,
        },
        {
          path: "routes/:routeUUID",
          element: <GuideRoutesEdit />,
        },
        {
          path: "routes/new",
          element: <GuideRoutesEdit />,
        },
        {
          path: "themes",
          element: <GuideTheme />,
        },
        {
          path: "ux",
          element: <GuideThemeSettings />,
        },
        {
          path: "beacons",
          element: <Beacons />,
        },
        {
          path: "beacons/:beaconUUID",
          element: <BeaconDetails />,
        },
      ],
      element: (
        <GuideLayout
          children={
            <React.Suspense fallback={<LoadingComponent />}>
              <GuideApplicationDetails />
            </React.Suspense>
          }
        />
      ),
    },
    //PORTAL
    {
      path: "/portal",
      element: (
        <PortalLayout
          children={
            <React.Suspense fallback={<LoadingComponent />}>
              <Portals />
            </React.Suspense>
          }
        />
      ),
    },
    {
      path: "/portal/:portalUUID",
      children: [
        {
          path: "pages",
          element: <PortalPages />,
        },
        {
          path: "pages/:pageUUID",
          element: <PortalPageEdit />,
        },
        {
          path: "pages/new",
          element: <PortalPageEdit />,
        },
        {
          path: "dashboard",
          element: <PortalAppDetails />,
        },
        {
          path: "navigation",
          element: <PortalNavigation />,
        },
        {
          path: "navigation/:menuUUID",
          element: <PortalMenuDetails />,
        },
      ],
      element: (
        <PortalLayout
          children={
            <React.Suspense fallback={<LoadingComponent />}>
              <PortalDetails />
            </React.Suspense>
          }
        />
      ),
    },
    //PORTAL
    {
      path: "/geo",
      element: (
        <GeoLayout
          children={
            <React.Suspense fallback={<LoadingComponent />}>
              <GeoMap />
            </React.Suspense>
          }
        />
      ),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
