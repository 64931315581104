import axios, { AxiosError, AxiosRequestConfig } from "axios";
import React from "react";
import { API_URL } from "./config";
import CryptoJS from "crypto-js";
import moment from "moment-timezone";

export function getToken(): string {
  const userAuth = localStorage.getItem(`${getTenantId()}_token`); //sessionStorage.getItem("authUser");
  return userAuth ? userAuth : "";
}

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common = {
  Authorization: getToken() ? "Bearer " + getToken() : "",
  "X-TenantID": getTenantId(),
};

export function getDefaultHeaders() {
  const token = getTenantId();
  return {
    Authorization: token ? "Bearer " + token : "",
    "X-TenantID": getTenantId(),
  };
}

export function getTenantId(): string {
  try {
    const path = window.location.pathname;
    const parts = path.split("/");
    return parts[1];
  } catch (e) {
    return "";
  }
}

function handleError(err: AxiosError<any>) {
  if (err.response) {
    const errorMsg = err.response?.data?.message;
    const statusCode = err?.request?.status;
    // Handle 401 differently
    if (statusCode === 401) {
      throw err;
    } else if (statusCode >= 500 && statusCode <= 599) {
      throw new Error(
        `Something went wrong on the server. Error code: ${err.request.status}. Message: ${err.response?.data?.message}`
      );
    }

    if (errorMsg) throw new Error(err.response.data.message);
    throw new Error(`Something went wrong. Error code: ${err.request.status}.`);
  }
  throw new Error("Something went wrong.");
}

export const fetchData = (API_URL: string, config?: AxiosRequestConfig) =>
  axios
    .get(API_URL, config)
    .then((res) => res.data)
    .catch((error) => handleError(error));

export const postData = (API_URL: string, data?: any, config?: object) =>
  axios
    .post(API_URL, data, config)
    .then((res) => res.data)
    .catch((error) => handleError(error));

export const updateData = (API_URL: string, data: any, config?: object) =>
  axios
    .put(API_URL, data, config)
    .then((res) => res.data)
    .catch(handleError);

export const deleteData = (API_URL: string, data?: any) =>
  axios.delete(API_URL, { data }).catch(handleError);

export const exportFile = (url: string, data?: any) => {
  let headers = {
    Authorization: `Bearer ${getToken()}`,
    "X-TenantID": getTenantId(),
  };

  return axios
    .post(url, data, { headers, responseType: "arraybuffer" })
    .then((response) => {
      const { data } = response;
      // eslint-disable-next-line
      let fileName =
        response?.headers["content-disposition"]?.match(/\"(.*?)\"/);
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      // @ts-ignore
      link.setAttribute(
        "download",
        "export_" + (fileName ? fileName[1] : "terms.xlsx")
      ); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(handleError);
};

export const downloadFile = (downloadUrl: string, fileName: string) => {
  let headers = {
    Authorization: `Bearer ${getToken()}`,
    "X-TenantID": getTenantId(),
  };
  axios
    .get(downloadUrl, { headers, responseType: "blob" })
    .then((response) => {
      const { data } = response;
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => handleError(error));
};

export const downloadMultipleFiles = (url: string, body: any) => {
  let headers = {
    Authorization: `Bearer ${getToken()}`,
    "X-TenantID": getTenantId(),
  };
  return axios
    .post(url, body, { headers, responseType: "blob" })
    .then((response) => {
      const { data, headers } = response;
      let fileName = headers["content-disposition"].split('"')[1];
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([data], { type: headers["content-type"] })
      );
      link.setAttribute("download", decodeURIComponent(fileName));
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => handleError(error));
};

export const downloadThumb = (
  downloadUrl: string,
  setThumbnail: React.Dispatch<React.SetStateAction<any>>
) => {
  let headers = {
    Authorization: `Bearer ${getToken()}`,
    "X-TenantID": getTenantId(),
  };
  let base64Data = null;
  axios
    .get(downloadUrl, { headers, responseType: "blob" })
    .then((response) => {
      const { data } = response;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = function () {
        base64Data = reader.result;
        setThumbnail(base64Data);
      };
    })
    .catch((error) => handleError(error));
};

/**
 * @function buildParameters creates query parameters with encoding based on the object properties
 * @object object to pass that has all the parameters
 * */
export const buildParameters = (object: any, append?: boolean) => {
  return Object.keys(object)
    .filter((prop) =>
      Boolean(
        object[prop] !== null &&
          object[prop] !== undefined &&
          object[prop] !== ""
      )
    )
    .map((prop, index) => {
      const symbol = index === 0 && !append ? "?" : "&";
      return `${symbol}${prop}=${encodeURI(object[prop])}`;
    })
    .join("");
};

export function createMap<T>(array: T[], prop: keyof T | string = "id") {
  // @ts-ignore
  return new Map(array.map((item) => [item[prop], item]));
}

// @ts-ignore
export const combineStrings = (divider: string, ...strings) => {
  return [...strings].filter((x) => x).join(divider);
};

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const atob = require("atob");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c: string) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload); //Object { sub: "admin", exp: 1677417674, iat: 1661865674, email: "vangelis.nomikos@datawise.ai" }
}

export function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

export function makeID(length = 10) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function bytesToSize(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) {
    return bytes + " " + sizes[i];
  }
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
}

export function bytesToMB(bytes: number) {
  return Number((bytes / Math.pow(1024, 2)).toFixed(1));
}

export function mbToBytes(mb: number) {
  return mb * Math.pow(1024, 2);
}

export const percentage = (used: number, total: number): string =>
  ((used / total) * 100).toFixed(1);

export const ActualDateTime = (
  datetime: string,
  format = "ll HH:mm:ss"
): string => {
  if (!datetime) return "-";
  const timezone = localStorage.getItem("timezone") || "Europe/Athens";
  return moment.utc(datetime).tz(timezone).format(format);
};

export const ActualTime = (datetime: string, format = "HH:mm:ss"): string => {
  const timezone = localStorage.getItem("timezone") || "Europe/Athens";
  return moment.utc(datetime).tz(timezone).format(format);
};

export const ActualDate = (datetime: string, format = "ll"): string => {
  const timezone = localStorage.getItem("timezone") || "Europe/Athens";
  return moment.utc(datetime).tz(timezone).format(format);
};

export enum LogLevel {
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  TRACE = "TRACE",
}

export function logError(level: LogLevel, message: string, details: string) {
  const payload = { level, message, details, component: "UI" };
  return postData(`${API_URL}/logs`, payload).catch((ex) => console.log(ex));
}

export function sortBy(a: any, b: any, type: string) {
  if (a[type] < b[type]) {
    return -1;
  }
  if (a[type] > b[type]) {
    return 1;
  }
  return 0;
}

export function kFormatter(num: number) {
  return Math.abs(num) > 999
    ? // @ts-ignore
      Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}

export const encodeToBase64 = (text: string) => {
  const encJson = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    "share"
  ).toString();
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
};

export const decodeFromBase64 = (data: string) => {
  const decData = CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(decData, "share").toString(
    CryptoJS.enc.Utf8
  );
  return JSON.parse(bytes);
};

export const reorder = (
  list: ArrayLike<any>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
