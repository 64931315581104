import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import IAMPermission from "../../../models/IAMPermission";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";

export const defaultMenu = [
  {
    icon: HomeOutlinedIcon,
    name: "sidenav.myContent",
    path: "store/content",
  },
  {
    icon: AccessTimeOutlinedIcon,
    name: "sidenav.recent",
    path: "store/recent",
  },
  {
    icon: StarOutlineOutlinedIcon,
    name: "sidenav.popular",
    path: "store/popular",
  },
  {
    icon: PieChartOutlinedIcon,
    name: "sidenav.reports",
    path: "store/reports",
  },
  {
    icon: DeleteOutlineOutlinedIcon,
    name: "sidenav.trash",
    path: "store/trash",
  },
];

export const ControlPanelMenu = [
  {
    name: "admin.control.panel.administrativeTools",
    path: "store/control-panel/administrative-tools",
    icon: AdminPanelSettingsOutlinedIcon,
    color: "#DA100B",
    description: "admin.control.panel.administrativeTools.subtitle",
    permissions: [IAMPermission.developer_console],
  },
  {
    name: "admin.control.panel.dataModeling",
    path: "store/control-panel/data-modeling",
    icon: DatasetOutlinedIcon,
    color: "#1F8B24",
    description: "admin.control.panel.dataModeling.subtitle",
    permissions: [IAMPermission.developer_console],
  },
  {
    name: "admin.control.panel.integration",
    path: "store/control-panel/integration",
    icon: IntegrationInstructionsOutlinedIcon,
    color: "primary.main",
    description: "admin.control.panel.integration.subtitle",
    permissions: [IAMPermission.developer_console],
  },
  {
    name: "admin.control.panel.interoperability",
    path: "store/control-panel/interoperability",
    icon: LayersOutlinedIcon,
    color: "#FF6C40",
    description: "admin.control.panel.interoperability.subtitle",
    permissions: [IAMPermission.developer_console],
  },
  {
    name: "admin.control.panel.userManagement",
    path: "store/control-panel/user-management",
    icon: ManageAccountsOutlinedIcon,
    color: "#b82e8a",
    description: "admin.control.panel.userManagement.subtitle",
    permissions: [
      IAMPermission.store_settings_groups_view,
      IAMPermission.store_settings_users_view,
    ],
  },
];

export const QuickLinksMenu = [
  {
    icon: EventNoteOutlinedIcon,
    name: "ms.vocabularies",
    path: "store/control-panel/data-modeling/metadata-schemas/vocabularies",
    permissions: [IAMPermission.store_settings_tags_view],
  },
  {
    icon: LocalOfferOutlinedIcon,
    name: "sidenav.settings.tags",
    path: "store/control-panel/data-modeling/tags",
    permissions: [IAMPermission.store_settings_tags_view],
  },
];
