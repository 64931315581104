import React, { Fragment, useMemo } from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Datastream } from "../../models/Datastream";
import { bytesToSize } from "../../utils/utils";
import { useDatastreamFileImage } from "../../hooks/datastream/useDatastreamFileImage";
import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined";
import { ObjectType } from "../../models/ObjectType";
import { FolderOpen } from "@mui/icons-material";
import { RepoxType } from "../../models/RepoxType";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PublicIcon from "@mui/icons-material/Public";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { useTranslation } from "react-i18next";
import IAMPermission from "../../models/IAMPermission";
import { useIAMPermission } from "../../store/userStore";

type DatastreamCardProps = {
  datastream: Datastream;
  menu?: JSX.Element;
  action?: JSX.Element;
  objectId?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onDoubleClick?: React.MouseEventHandler<HTMLElement>;
  isActive?: boolean;
  isSelected?: boolean;
  onSelect?: React.MouseEventHandler<HTMLElement>;
  selectable?: boolean;
  debugMode?: any;
  isParentShared?: boolean;
};

function DatastreamCard(props: DatastreamCardProps) {
  const {
    datastream,
    menu,
    onClick,
    isActive,
    objectId,
    action,
    onDoubleClick,
    isSelected,
    onSelect,
    selectable,
    debugMode,
    isParentShared,
  } = props;
  const {t} = useTranslation();
  const iam = useIAMPermission();
  const { FileIcon, color } = useDatastreamFileImage(
    datastream.bitstreamFile?.mimeType ?? "",
    datastream?.typeName
  );
  const isShared = isParentShared || datastream.shared;

  const id = useMemo(() => {
    if (datastream.repoxType !== RepoxType.REPOX_BITSTREAM) {
      if (datastream.objectType === ObjectType.CONTAINER) {
        return `linkContainer/${datastream.uuid}`;
      } else if (datastream.objectType === ObjectType.GUIDE) {
        return `linkGuide/${datastream.uuid}`;
      } else if (datastream.objectType === ObjectType.GEO) {
        return `linkGeo/${datastream.uuid}`;
      } else {
        return `link/${datastream.uuid}`;
      }
    }
    return datastream.uuid;
  }, [datastream]);

  return (
    <Card
      variant={"outlined"}
      id={id}
      onClick={selectable && onSelect ? (iam.hasSome([IAMPermission.store_datastream_remove, IAMPermission.store_datastream_download]) ? onSelect : undefined) : onClick}
      onDoubleClick={onDoubleClick}
      sx={{
        border: "1px solid",
        borderColor: isActive ? "primary.main" : "divider",
      }}
    >
      {/*{menu && menu}*/}
      <CardContent style={{ padding: 0 }}>
        {action && (
          <Stack flexDirection={"row"} flexGrow={1} sx={{ mb: -4 }}>
            <div style={{ flexGrow: 1 }}></div> {action}
          </Stack>
        )}
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          // columnGap={2}
          sx={{ p: 2 }}
        >
          <Collapse in={selectable} orientation={"horizontal"}>
            <Checkbox
              size={"small"}
              checked={isSelected}
              id={datastream.uuid}
              //onChange={onSelect}
              sx={{ mr: 2 }}
              disabled={!iam.has(IAMPermission.store_datastream_remove) && !iam.has(IAMPermission.store_datastream_download)}
            />
          </Collapse>

          {objectId ? (
            <Fragment>
              {datastream.objectType === ObjectType.CONTAINER ? (
                <Stack
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  id={id}
                  sx={{
                    width: "82px",
                    height: "82px",
                  }}
                >
                  <Stack
                    sx={{
                      backgroundColor: "secondary.main",
                      borderRadius: 12.5,
                      width: "60px",
                      height: "60px",
                    }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <FolderOpen fontSize={"large"} sx={{ opacity: 0.6 }} />
                    {objectId && (
                      <ShortcutOutlinedIcon
                        sx={{
                          fontSize: 22,
                          position: "absolute",
                          marginTop: 6,
                          marginLeft: -4,
                          borderRadius: 12.5,
                          color: (theme) =>
                            theme.palette.mode === "dark"
                              ? "common.white"
                              : "common.black",
                          backgroundColor: "background.paper",
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Stack
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  id={id}
                >
                  <Stack
                    sx={{
                      width: "82px",
                      height: "82px",
                      backgroundColor: "secondary.main",
                      borderRadius: 1,
                    }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {datastream.repoxType === RepoxType.REPOX_LINK ? (
                      <InsertDriveFileOutlinedIcon
                        sx={{ fontSize: 45, opacity: 0.6 }}
                      />
                    ) : (
                      <FileIcon sx={{ fontSize: 45, opacity: 0.6 }} />
                    )}
                  </Stack>
                  {datastream.repoxType === RepoxType.REPOX_LINK && (
                    <ShortcutOutlinedIcon
                      sx={{
                        fontSize: 22,
                        position: "absolute",
                        marginBottom: -0.3,
                        marginLeft: -8,
                        borderRadius: 12.5,
                        color: (theme) =>
                          theme.palette.mode === "dark"
                            ? "common.white"
                            : "common.black",
                        backgroundColor: "background.paper",
                      }}
                    />
                  )}
                </Stack>
              )}
            </Fragment>
          ) : (
            <Stack flexDirection={"column"} alignItems={"center"} id={id}>
              {datastream.bitstreamFile?.thumbnails?.mediumUrl ? (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    width: "82px",
                    height: "82px",
                    borderRadius: 1,
                  }}
                >
                  <Tooltip
                    title={
                      <img
                        src={datastream.bitstreamFile.thumbnails.mediumUrl}
                        alt={" "}
                        style={{ borderRadius: 8 }}
                      />
                    }
                    placement="right"
                    style={{ maxWidth: "400px" }}
                    arrow
                  >
                    <img
                      src={datastream.bitstreamFile.thumbnails.mediumUrl}
                      alt={" "}
                      style={{
                        width: "82px",
                        height: "82px",
                        objectFit: "cover",
                        borderRadius: "inherit",
                      }}
                    />
                  </Tooltip>
                </Stack>
              ) : (
                <Stack
                  sx={{
                    width: "82px",
                    height: "82px",
                    backgroundColor: "secondary.main",
                    borderRadius: 1,
                  }}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FileIcon sx={{ fontSize: 45, opacity: 0.6 }} />
                </Stack>
              )}
            </Stack>
          )}
          {menu && menu}
          {!objectId && datastream.publishedAt && (
            <Tooltip title={t("tooltip.published")}>
              <PublicIcon
                fontSize={"small"}
                color={"primary"}
                sx={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  mr: debugMode ? (isShared ? 10 : 7) : (isShared ? 4 : 1),
                  mb: 1,
                }}
              />
            </Tooltip>
          )}

          {!objectId && isShared && (
            <Tooltip title={t("tooltip.shared")}>
              <PeopleOutlinedIcon
                fontSize={"small"}
                sx={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  mr: debugMode ? 7 : 1,
                  mb: 1,
                }}
              />
            </Tooltip>
          )}

          {!objectId && debugMode && debugMode === true && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  mr: 1,
                  mb: 1.2,
                  width: 17,
                  height: 17,
                  borderRadius: 12.5,
                  backgroundColor: "secondary.main",
                }}
              >
                <Stack
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"100%"}
                  width={"100%"}
                >
                  <Typography
                    sx={{ fontSize: 8 }}
                    color={
                      datastream.textExtracted ? "primary" : "textSecondary"
                    }
                  >
                    TE
                  </Typography>
                </Stack>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  mr: 4,
                  mb: 1.2,
                  width: 17,
                  height: 17,
                  borderRadius: 12.5,
                  backgroundColor: "secondary.main",
                }}
              >
                <Stack
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"100%"}
                  width={"100%"}
                >
                  <Typography
                    sx={{ fontSize: 8 }}
                    color={datastream.textChunked ? "primary" : "textSecondary"}
                  >
                    TC
                  </Typography>
                </Stack>
              </Box>
            </>
          )}

          <Stack
            flexDirection={"column"}
            alignItems={"flex-start"}
            rowGap={0.5}
            ml={2}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            <Typography variant={"body1"} noWrap sx={{ mb: 0.5 }}>
              {datastream.label}
            </Typography>

            <Stack
              alignItems={"center"}
              direction={"row"}
              columnGap={0.5}
              mb={1}
            >
              <FileIcon fontSize={"small"} sx={{ color: color }} />
              <Typography color={"textSecondary"} variant={"body2"}>
                {objectId || datastream.objectType === ObjectType.GUIDE
                  ? bytesToSize(1024)
                  : bytesToSize(datastream.bitstreamFile?.filesize)}
              </Typography>
            </Stack>
            <Typography noWrap color={"primary"} variant={"body2"}>
              {datastream.typeName}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default DatastreamCard;
